<template>
  <!-- 绑定容器 -->
  <div class="echarts-view" ref="echart"></div>
</template>

<script>
/* eslint-disable */
import * as echart from "echarts";
export default {
  props: {
    //是否为有轴图表
    isAxisChart: {
      type: Boolean,
      default: true,
    },
    //图表数据
    chartData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  //监听图表数据变化
  watch: {
    chartData: {
      handler: function () {
        this.initChart();
      },
      deep: true,
    },
  },
  methods: {
    //根据图表类型配置图表项
    initChart() {
      this.initChartData();
      if (this.echart) {
        this.echart.setOption(this.options);
      } else {
        this.echart = echart.init(this.$refs.echart);
        this.echart.setOption(this.options);
      }
    },
    //根据图表类型导入图表数据
    initChartData() {
      if (this.isAxisChart) {
        //设置X轴
        this.axisOptions.xAxis.data = this.chartData.xData;
        //设置图表类型
        this.axisOptions.series = this.chartData.series;
      } else {
        //无轴表
        this.normalOptions.series = this.chartData.series;
      }
    },
  },
  computed: {
    //判断图表类型
    options() {
      return this.isAxisChart ? this.axisOptions : this.normalOptions;
    },
  },
  data() {
    return {
      //有轴表配置项
      axisOptions: {
        legend: {
          //字体颜色
          textStyle: {
            color: "#333",
          },
        },
        //grid组件离容器左侧的距离
        grid: {
          left: "15%",
        },
        //提示框触发规则
        tooltip: {
          trigger: "item",
        },
        //X轴配置
        xAxis: {
          type: "category",
          data: [],
          axisLine: {
            lineStyle: {
              color: "#b37e25",
            },
          },
          axisLabel: {
            interval: 0, //X轴间隔
            color: "#b37e25",
            rotate: 30
          },
        },
        //Y轴配置
        yAxis: [
          {
            type: "value",
            axisLine: {
              lineStyle: {
                color: "#b37e25",
              },
            },
          },
        ],
        //颜色配置
        color: [
          "#b37e25",
          "#b6a2de",
          "#5ab1ef",
          "#ffb980",
          "#d87a80",
          "#8d98b3",
        ],
        //图表类型
        series: [],
      },
      //无轴表配置项
      normalOptions: {
        //提示框触发规则
        tooltip: {
          trigger: "item",
        },
        //颜色配置
        color: [
          "#0f78f4",
          "#dd536b",
          "#9462e5",
          "#a6a6a6",
          "#e1bb22",
          "#39c362",
          "#3ed1cf",
        ],
        //图表类型
        series: [],
      },
    };
  },
};
</script>

<style scoped>
.echarts-view {
  width: 100%;
}
</style>