<template>
    <div class="dswap">
        <Logo />
        <div class="box animate__animated animate__fadeIn">
            <div class="header">
                <a-icon class="icon back" @click="$router.go(-1)" type="left" />
                <div class="title">
                    <img class="title-img" src="@/assets/svgs/bless.svg" alt="">
                    <span class="text">{{ $t('defi.title') }}</span>
                </div>
                <div class="opt swap-rise">
                    <a-icon class="icon" v-if="swapRise==1" type="rise" @click="swapRise=0" />
                    <a-icon class="icon" v-else type="swap" @click="swapRise=1" />
                </div>
            </div>
            <div v-if="swapRise==1" class="container swap-container">
                <div class="mg30 flex tokens ">
                    <div class="flex column usdt">
                        <img class="token_icon" src="@/assets/imgs/usdtlogo.png" alt="usdt">
                        <span>USDT</span>
                    </div>
                    <div class="swap">
                        <img src="@/assets/svgs/infinity-light.svg" >
                    </div>
                    <div class="flex column bct">
                        <div class="bct_icon">
                            <img class="token_icon" src="@/assets/imgs/bctlogo.png" alt="bct">
                        </div>
                        <span>{{ $t('common.currency') }}</span>
                    </div>
                </div>
                <a-form-item>
                    <div class="label flex" slot="label">
                        <span>{{$t('wallet.withdrawl.amount')}}</span>
                        <span class="color-gray"></span>
                    </div>
                    <a-input size="large" type="number" ref="amount" v-model="convertAmount"></a-input>
                </a-form-item>
                <div class="mg30 btn-primary" @click="convert">
                    {{$t('defi.join')}}
                </div>
            </div>
            <div v-else class="container rise-container">
                <div class="item-boxs">
                    <div class="box item" :class="itemOnIdx==0?'on':''" @click="itemOnIdx=0">
                        <div class="value">{{ price }}</div>
                        <div class="label">{{$t('common.currency')}}/USDT</div>
                    </div>
                    <div class="box item" :class="itemOnIdx==1?'on':''" @click="itemOnIdx=1">
                        <div class="value">{{ count }}</div>
                        <div class="label">{{ $t('defi.countLabel') }}</div>
                    </div>
                </div>
                <div class="line-box">
                    <EchartsView :chartData="lineData" style="height: 375px" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Logo from "@/components/Logo.vue";
import Shelldao from "@/web3/tron/shelldao";
import EchartsView from "@/components/EchartsView.vue";
export default {
    name: "Swap",
    data() {
        return {
            loading: false,
            rerunModalVisible: false,
            confirmLoading: false,
            swapRise: 0,
            itemOnIdx: 0,
            convertAmount: 100,
            rate: 1,
            approvedAmount: 0,
            userUsdtBalance: 0,
            price: 0.1,
            count: 1000,
            userinfo: this.$store.state.userinfo,
            lines: [],
            lineData: { xData: [], series: [] },
        };
    },
    components: { Logo, EchartsView },
    mounted() {
        Shelldao.init((r)=>{
            r.gUsdtToken.balanceOf(r.gWalletAddress).call().then((res)=>{
                this.userUsdtBalance = res.toNumber() / 1000000;
                console.log('usdt balance:', this.userUsdtBalance);
            });
            Shelldao.getKaishanApprovedAmount('usdt',(res)=>{
                let _approvedAmount=0;
                if(res.remaining===undefined){
                    _approvedAmount = res.toNumber();
                }else{
                    _approvedAmount = res.remaining.toNumber();
                }
                this.approvedAmount = _approvedAmount/1000000;
                console.log(this.approvedAmount);
            })
        });
        this.getPrice();
        this.getData();
        // this.initLineData();
    },
    watch: {
        swapRise(newVal){
            console.log(newVal)
            if(newVal==0){
                this.$nextTick(()=>{
                    this.initLineData();
                })
            }
        }
    },
    methods: {
        convert(){
            if(this.loading) return;
            if(this.convertAmount<100){
                this.$message.error(this.$t('defi.min'));
                return;
            }
            if(this.userUsdtBalance <= this.convertAmount){
                this.$message.error(this.$t('defi.lackofbalance'));
                return;
            }
            let _this = this;
            if(_this.approvedAmount <= _this.convertAmount){
                _this.loading = true;
                Shelldao.kaishanApproveToken(_this.convertAmount*10000000, 'usdt', ()=>{
                    Shelldao.buyBct(_this.convertAmount, ()=>{
                        _this.$message.success('success');
                        _this.loading = false;
                    });
                })
            }else{
                _this.loading = true;
                Shelldao.buyBct(_this.convertAmount, ()=>{
                    _this.$message.success('success');
                    _this.loading = false;
                });
            }
        },
        getPrice(){
            let _this = this
            this.$http.call(this.$http.api.price).then(function(res){
                console.log(res.data.data);
                if(res.data.code == '200'){
                    _this.price = res.data.data.price;
                    _this.count = res.data.data.count
                }
            },function(res){
                console.log('something goes wrong...');
                console.log(res);
            });
        },
        getData(){
            let _this = this
            this.$http.call(this.$http.api.priceLine).then(function(res){
                if(res.data.code == '200'){
                    _this.lines = res.data.data;
                    _this.initLineData();
                }
            },function(res){
                console.log('something goes wrong...');
                console.log(res);
            });
        },
        initLineData() {
            this.lineData.xData = this.lines.map(item=>item.date);
            this.lineData.series  = [
                {
                    data: this.lines.map(item=>item.max_price),
                    type: 'line',
                    smooth: true,
                }
            ];
        }
    },
};
</script>
<style lang="less" scoped>
.dswap {
    width:100%;
    padding: 0 50px;
    text-align: center;
    .box{
        padding: 20PX;
        .header {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            .back{
                position: absolute;
                left: 0;
            }
            .opt{
                position: absolute;
                right: 0;
            }
            .icon {
                font-size: 20PX;
                color: #a8a8a8;
            }
            .title {
                font-weight: bold;
                font-size: 16PX;
                color: @primary-color;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .title-img {
                    height: 50PX;
                }
            }
            .swap-rise {
                width: 100px;height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background-color: @primary-color-shadow;
                box-shadow: 0 0 10PX @primary-color;
                .icon {
                    color: #fff;
                }
            }
        }
        .swap-container {
            .tokens{
                justify-content: space-around;
                .bct_icon{
                    background: #ffffff;
                    border-radius: 50%;
                }
                .token_icon{
                    width: 50PX;
                }
                .swap{
                    width: 40PX;
                    // opacity: .5;
                    img {
                        width: 100%;
                    }
                }
            }
            // .rate{
            //     justify-content: center;
            // }
            .tips{
                text-align: left;
                color: @gray;
            }
        }
        .rise-container {
            padding: 50px 0;
            .item-boxs {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-left: -50px;
                margin-right: -50px;
                .item {
                    flex: 1;
                    margin: 15px 50px;
                    background: #fff;
                    color: @primary-color;
                    box-shadow: 0 0 8PX @primary-color-shadow;
                    .label {
                        color: inherit;
                    }
                    .value {
                        font-size: 58px;
                        padding-bottom: 15px;
                        font-weight: bold;
                    }
                }.on {
                    background: @primary-color;
                    color: #fff;
                }
            }
            .line-box {
                margin-left: -20PX; margin-right: -20PX;
            }
        }   
        
    }
}
</style>
